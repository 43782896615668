import "src/components/organisms/meal-selections/confirm-delivery.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/organisms/meal-selections/confirm-delivery.css.ts.vanilla.css\",\"source\":\"Ll8xZGlpbHlsMCB7CiAgZm9udC1mYW1pbHk6IGFjdW1pbi1wcm8tc2VtaS1jb25kZW5zZWQsIEFyaWFsLCBzYW5zLXNlcmlmOwp9Ci5fMWRpaWx5bDEgewogIG1hcmdpbi1ib3R0b206IDJyZW07Cn0KLl8xZGlpbHlsMiB7CiAgZm9udC1mYW1pbHk6IGFjdW1pbi1wcm8tc2VtaS1jb25kZW5zZWQsIEFyaWFsLCBzYW5zLXNlcmlmOwogIHdpZHRoOiAxMDAlOwogIGJhY2tncm91bmQtY29sb3I6ICNkNGY5ZTM7CiAgcGFkZGluZzogMC4zcmVtIDAuNXJlbTsKfQouXzFkaWlseWwzIHsKICBtYXJnaW4tbGVmdDogMC41cmVtOwp9Ci5fMWRpaWx5bDQgewogIGZvbnQtZmFtaWx5OiBhY3VtaW4tcHJvLXNlbWktY29uZGVuc2VkLCBBcmlhbCwgc2Fucy1zZXJpZjsKICBsaXN0LXN0eWxlOiBub25lOwogIGNvbG9yOiByZWQ7CiAgZm9udC1zdHlsZTogaXRhbGljOwp9Ci5fMWRpaWx5bDUgewogIGZvbnQtZmFtaWx5OiBhY3VtaW4tcHJvLXNlbWktY29uZGVuc2VkLCBBcmlhbCwgc2Fucy1zZXJpZjsKICBsaXN0LXN0eWxlOiBub25lOwogIGRpc3BsYXk6IGZsZXg7CiAgYWxpZ24taXRlbXM6IGJhc2VsaW5lOwp9Ci5fMWRpaWx5bDYgewogIGJvcmRlci1yYWRpdXM6IDUwJTsKICBib3JkZXI6IDFweCBzb2xpZCBibGFjazsKICBtYXJnaW4tcmlnaHQ6IDAuNXJlbTsKICB3aWR0aDogMzBweDsKICBoZWlnaHQ6IDMwcHg7CiAgZGlzcGxheTogZmxleDsKICBhbGlnbi1pdGVtczogY2VudGVyOwogIGp1c3RpZnktY29udGVudDogY2VudGVyOwp9Ci5fMWRpaWx5bDcgewogIHBhZGRpbmctdG9wOiAwLjI1cmVtOwp9Ci5fMWRpaWx5bDggewogIHRleHQtdHJhbnNmb3JtOiBjYXBpdGFsaXplOwp9Ci5fMWRpaWx5bDkgewogIGZvbnQtZmFtaWx5OiBhY3VtaW4tcHJvLXNlbWktY29uZGVuc2VkLCBBcmlhbCwgc2Fucy1zZXJpZjsKICBtYXJnaW46IDFyZW0gMDsKICBwYWRkaW5nOiAwOwp9\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var deliveryContainer = '_1diilyl1';
export var deliveryNumberHeader = '_1diilyl2';
export var header = '_1diilyl0';
export var itemCount = '_1diilyl6';
export var itemCountNumber = '_1diilyl7';
export var mealSelectionLi = '_1diilyl5';
export var mealTitle = '_1diilyl8';
export var noMealsLi = '_1diilyl4';
export var sectionContainer = '_1diilyl3';
export var sectionHeader = '_1diilyl9';