import "src/components/organisms/meal-selections/initial-selections.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/organisms/meal-selections/initial-selections.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VUy27bMBC8+ysWKAK3qGlIfilmLg2Q9lgURX+AItcyGz4Eio7tFv73gpSlSLYcIKeeBC2Xszu7M5zqrc3cMYG/I4CNNZ5smJbqSEHmmpQKD6RCJzcTGP+SGiv4jnv4aTUz4wnEk4cRgJIGyRZlsfUUZvPyEIIlE0KaguTWe6spJNOlQ/0wOo2mddE0FtXMFdIQb0sKi5hwbmR/xjPWaaY692bdew36rI89g3znvTVTxr18wXgjZ/y5cHZnBOFWWUfhQ5qtxCoLJZvI0+Lb+ut8AClC9IimWU10v5UeSVUyjqHbvWNlCOfWCXTEMSF3FYVlUmfXfVNY1L+RaiX/IIV0Ohvin1slQtDjwROB3DrmpTWhlMHOoMOEM4f6PGjgTPGP59BnmJWHT9crmNeDiZ1SSMsDVFZJAbli/PkNEkJWpWJHChuFhw7e4lVHNaeG0eC2LhTH+E5LQ0pnJ/DoJFMTqJipSCuzi6oABSsppGcspmRhiPSoKwocjUcXwr93lZebI+HWeDT+9ahtevnOpm8q9n8TWXW22XY9sNQugYbUoIg7yB0TNApOBlWZPFz3kPRlOgDc82n/8qIlEOz6VNv1hhla3PuI9Nac29R1TG38P88zkbGrlY4feVwq/HB2fGOrg1a+0NCVA1msvpfCbymkSfKy7xzmfRaFk/ElCF/iUZeKeQxv2U6bikKW3ME8uaurHkiDuVhF055GXzQKyaDiDtEAMwI+dvLu1+vwRISCPTNDHB4R0iGvR10XDGVOr8nL9ySzc3LLLFe2lmYnKb9MaujfHECa1Ow7Wms8dRqd/gGgC2T36wYAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var chooseDayHeader = 'mho7ry5';
export var container = 'mho7ry1';
export var daySelectorButtonBox = 'mho7ry3';
export var daySelectorRow = 'mho7ry2';
export var gridParent = 'mho7rya';
export var guidanceText = 'mho7ry0';
export var header = 'mho7ry4';
export var headerButtons = 'mho7ry8';
export var headerText = 'mho7ry7';
export var planTabRow = 'mho7ry6';
export var tabGrid = 'mho7ryb';
export var youNeedToChoose = 'mho7ry9';