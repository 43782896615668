import "src/components/organisms/meal-selections/confirm-selections-container.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/organisms/meal-selections/confirm-selections-container.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VUbW7bMAz9n1MIHQK3WOz4o11SFQbWG+wKjEQ7QmVJkOTG7dC7D5adxG6KdtjgH7Yp8vGRIl/SFZAeWEp+LwjZacvRUpKZjjgtBSc7CezpYUGIAc6FqinJLDa9YY+i3ntKtmlqut6gn9FWUh8occxqKR8Wb4tkQM8C+kFwv6ckS9Pl5Cz/KvMnifaCc1QTsCKA1Vbw2GNjJHiMmZZtoxwlFg2Cv85XJKvsTY/EhTMSXmiI6A2Db1yDoaQYC23A1kLFXhtK8mA7pbsN6aYOx6CPa70L/pVWPnbiFY94o6mCRsgXSoC1jVCxsTp22IiYacVROeQr8mgFyBVxoFzs0IpqQnCnvdfNBccfIafHzsfegnKVtg0lrTFoGTj87+yTarJkM+vZMCwk7Z/ZDKUTfpvAj2mpLSXfit2Gb+CCVPTIAi3yy+rob4jk88s79iZN7ubd2X7YHQZGeJDi9bI9/8zkssJ33LI5s/thL4A91Va3iseigRopaa28vuLggQbD2j3X37tGrpYFc8/1MHhl1A9efheNy3P+7xqpXBntvTd0vT4cDsmhSLSt13mapj1WtCxwWTCLzH+FVQkpy0hphRFx3uonLKOwtcffeAQoTgYObg/WwksZ3S5zRrazE11VDn0ZpSerFAoZmDLatd5H64FbT3NZ4NXNxaKdxSIbpOLdHObh9TDRmzCJPxvkAnrZQlQEFCfXDXTxCL29vzfdTbiNZCqW52TQet2Dvp1d8tHlHb1zzO1RzSZBxRh0kqVKYjeE9V8xF/21CK3oKFTDWVCr45jNax4SvP0B/fCA8uUFAAA=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var confirmSelectionsContainer = 'x3a0wc0';
export var confirmSelectionsGrid = 'x3a0wc3';
export var confirmSelectionsImage = 'x3a0wc2';
export var container = 'x3a0wc4';
export var countHeader = 'x3a0wc8';
export var divider = 'x3a0wc9';
export var goAheadAndSubmit = 'x3a0wc7';
export var header = 'x3a0wc5';
export var imgTags = 'x3a0wc1';
export var summaryHeader = 'x3a0wc6';